<template>
  <div class="ing-text-input">
    <label v-if="label" class="ing-text-input__label">{{ label }}</label>
    <div v-if="caption" class="ing-text-input__caption">
      {{ caption }}
    </div>
    <div class="ing-text-input__wrapper">
      <input
        ref="input"
        type="text"
        class="ing-text-input__element"
        :disabled="disabled"
        :value="inputValue"
        v-bind="$attrs"
        @blur="onBlur"
        @input="onInput"
      />
      <div class="ing-text-input__icon">
        {{ icon }}
      </div>
    </div>
  </div>
</template>

<script>
  import numeral from 'numeral';

  export default {
    name: 'IngCurrencyInput',
    inheritAttrs: false,
    props: {
      icon: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        inputValue: this.formatAmount(this.value),
      };
    },
    watch: {
      value() {
        this.inputValue = this.formatAmount(this.value);
      },
    },
    methods: {
      formatAmount(amount) {
        return new Intl.NumberFormat('de-DE').format(amount);
      },
      numeralValue(amount) {
        const numeralValue = numeral(amount).value();
        return numeralValue !== null ? numeralValue : this.value;
      },
      onInput(e) {
        this.$emit('input', this.numeralValue(e.target.value));
      },
      onBlur() {
        const newValue = this.numeralValue(this.inputValue);
        this.$emit('change', newValue);

        // in the case that the new value hasn't changed but the input value has
        // we need to set the input value element to the correct value as otherwise
        // it will not be detected by vue
        this.$refs.input.value = this.formatAmount(newValue);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';

  .ing-text-input {
    font-family: inherit;
    align-items: stretch;

    .ing-text-input {
      &__wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        font-family: inherit;
        flex-direction: row;
      }

      &__element {
        font-family: inherit;
        font-weight: bold;
        text-align: right;
        background: $Primary-white;
        padding: 8px 12px;
        border: 1px solid $Primary-Grey400;
        box-shadow: inset 0 2px 2px $Primary-Grey200;
        border-radius: 4px;
        width: 125px;
        outline: none;
        box-sizing: border-box;
        color: $Primary-Grey600;
        height: px2rem(40px);
        font-size: px2rem(16px);
        line-height: px2rem(24px);

        &:focus {
          border: 3px solid $Secondary-Indigo500;
          padding: 6px 10px;
          box-shadow: none;
        }

        &:disabled {
          border: 1px solid #d9d9d9;
          color: #a8a8a8;
        }
      }

      &__label {
        display: block;
        font-family: inherit;
        color: $Primary-Grey600;
        font-weight: 400;
        font-size: px2rem(16px);
        line-height: px2rem(24px);
      }

      &__caption {
        font-family: inherit;
        font-size: px2rem(14px);
        line-height: px2rem(20px);
        font-weight: normal;
        margin-bottom: 4px;
        color: $Primary-Grey400;
        text-align: left;
        width: 100%;
      }

      &__icon {
        margin-left: 8px;
        color: $Primary-Grey600;
        font-family: inherit;
        line-height: px2rem(40px);
        height: px2rem(40px);
      }
    }
  }
</style>
