export const gtmTrackingMixin = {
  computed: {
    canBeTracked() {
      const url = window.location.href;
      return url.includes('lendico');
    },
  },
  methods: {
    sendTrackingEvent(payload) {
      if (this.canBeTracked && window.dataLayer) {
        window.dataLayer.push(payload);
      }
    },
  },
};
