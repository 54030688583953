<template>
  <div class="len-custom-select">
    <div
      ref="select"
      class="len-custom-select__input"
      :class="{ 'len-custom-select__input--disabled': disabled }"
      @click="handleInputClick($event)"
    >
      <input
        type="text"
        :value="selectedOption.label"
        class="len-custom-select__input__element"
        v-bind="$attrs"
        readonly
        v-on="$listeners"
      />
      <div class="len-custom-select__input__icon">
        <LenArrowIcon :up="isOpen" />
      </div>
    </div>
    <div v-if="showMenu" class="len-custom-select__options">
      <span
        v-for="option in options"
        :key="option.value"
        class="len-custom-select__options__item"
        :class="{
          'len-custom-select__options__item--selected':
            selectedOption.value === option.value,
        }"
        @click="handleOptionClick($event, option)"
      >
        {{ option.label }}
      </span>
    </div>
  </div>
</template>

<script>
  import LenArrowIcon from '@/assets/LenArrowIcon';

  export default {
    name: 'CustomSelect',
    components: {
      LenArrowIcon,
    },
    inheritAttrs: false,
    props: {
      /* eslint-disable */
      value: {
        type: String | Number | Object | Boolean | null | undefined,
        default: ''
      },
      /* eslint-enable */
      options: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    computed: {
      selectedOption: function() {
        return (
          (this.options &&
            this.options.find((option) => option.value === this.value)) ||
          {}
        );
      },
      showMenu: function() {
        return !!(this.isOpen && this.options && this.options.length !== 0);
      },
    },
    mounted() {
      window.addEventListener('click', this.closeMenu);
    },
    beforeDestroy() {
      window.removeEventListener('click', this.closeMenu);
    },
    methods: {
      toggle: function() {
        if (!this.disabled) {
          this.isOpen = !this.isOpen;
        }
      },
      closeMenu: function() {
        if (!this.disabled) {
          this.isOpen = false;
        }
      },
      handleOptionClick: function(event, option) {
        if (!this.disabled) {
          event.stopPropagation();
          this.toggle();
          this.$emit('input', option.value);
        }
      },
      handleInputClick: function(event) {
        if (!this.disabled) {
          event.stopPropagation();
          this.toggle();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';

  .len-custom-select {
    position: relative;

    &__input {
      position: relative;
      background: #f5f5f5;
      color: #2e2e2e;
      -webkit-font-smoothing: antialiased;

      height: 40px;
      display: flex;
      border-radius: 4px;

      &__icon,
      &__element {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;
      }

      &__icon {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        padding: 11px 15px;
        display: flex;
        align-items: center;
      }

      &__element {
        padding: 11px 39px 11px 15px;
        background: transparent;
        outline: none;
        border: none;
        width: 100%;
        font-family: inherit;
      }
    }

    &__options {
      background: white;
      border: none;
      box-shadow: 0px 2px 8px rgba(46, 46, 46, 0.15);
      border-radius: 4px;

      position: absolute;
      display: -ms-grid;
      display: grid;
      right: 0;
      left: 0;
      top: 48px;
      overflow: hidden;
      z-index: 1;

      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;

      &__item {
        padding: 12px;
        text-align: initial;
        color: #2e2e2e;

        &:hover {
          background-color: #29bdba;
          color: white;
          cursor: pointer;
          font-weight: 600;
        }

        &--selected {
          color: #29bdba;
          font-weight: 600;
        }
      }
    }
  }

  /*
    * Mobile
    */
  @media screen and (max-width: $mobile-l) {
    .len-custom-select {
      &__input {
        &__icon,
        &__element {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
</style>

<style lang="scss">
  .len-custom-select__input--disabled {
    .len-custom-select__input__element,
    .len-custom-select__input__icon {
      color: #9d9d9d;
      cursor: default;

      svg path {
        stroke: #9d9d9d;
      }
    }
  }
</style>
