export default {
  LENDICO: 'unsecure_sme',
  ING: 'blacksea_sme',
  WCL: 'blacksea_wcl',

  LINE_OF_CREDIT: 'blacksea_wcl',
  TERM_LOAN_EQUAL_INSTALLMENT: 'blacksea_sme',

  LINE_OF_CREDIT_CAPS: 'LINE_OF_CREDIT',
  TERM_LOAN_EQUAL_INSTALLMENT_CAPS: 'TERM_LOAN_EQUAL_INSTALLMENT',
};
