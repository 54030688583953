<template>
  <OldLoanCalculator
    v-if="theme === COMMONS.THEME.LENDICO"
    :captionColor="captionColor"
  />
  <LoanCalculator
    v-else-if="theme === COMMONS.THEME.ING"
    :container="container"
  />
</template>

<script>
  import COMMONS from '@/constants/COMMONS';

  import OldLoanCalculator from '@/components/OldLoanCalculator';
  import LoanCalculator from '@/components/LoanCalculator';

  import '@/locales/de';
  import { Api } from '@/api';
  import Vue from 'vue';

  const api = new Api();

  Vue.use(Api);

  export default {
    components: {
      OldLoanCalculator,
      LoanCalculator,
    },
    props: {
      captionColor: {
        type: String,
        default: '#FFFFFF',
      },
      container: {
        type: String,
        default: 'APP',
        validator: (prop) => Object.values(COMMONS.CONTAINER).includes(prop),
      },
      theme: {
        type: String,
        default: 'LENDICO',
        validator: (prop) => Object.values(COMMONS.THEME).includes(prop),
      },
    },
    created() {
      this.COMMONS = COMMONS;
    },
    api,
  };
</script>
