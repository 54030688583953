<template>
  <label
    class="radio-container"
    :class="{
      'radio-container--focus': isFocused,
    }"
  >
    <span class="radio-container__checkmark">
      <span
        class="radio-checkmark"
        :class="{
          'radio-checkmark--checked': isChecked,
        }"
      />
    </span>
    <span class="radio-container__label">
      <slot name="label">{{ label }}</slot>
    </span>
    <input
      :value="value"
      :checked="isChecked"
      :name="name"
      type="radio"
      class="form-radio"
      v-bind="$attrs"
      @change="onChange"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />
  </label>
</template>
<script>
  export default {
    name: 'IngRadioButton',
    inheritAttrs: false,
    model: {
      prop: 'selectedValue',
      event: 'change',
    },
    props: {
      selectedValue: {
        type: [String, Number, Boolean],
        default: null,
      },
      value: {
        type: [String, Boolean],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isFocused: false,
      };
    },
    computed: {
      isChecked() {
        return this.selectedValue === this.value;
      },
    },
    methods: {
      onChange() {
        this.$emit('change', this.value);
      },
    },
  };
</script>
<style scoped lang="scss">
  @import '@/styles/ing.orange.juice';

  .radio-container {
    font-family: inherit;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;

    &--focus {
      .radio-checkmark {
        border: 1px solid $Primary-Orange;
      }
    }

    &__label {
      font-family: inherit;
      display: flex;
      flex: 1 1 auto;
      color: #333;
      align-items: center;
    }

    &__checkmark {
      display: flex;
      flex: 0 1 auto;
      margin-right: 8px;
      align-items: center;
    }
  }

  /* Hide the browser's default radio button */
  .radio-container input.form-radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
  }

  /* Create a custom radio button */
  .radio-checkmark {
    position: relative;
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #9b9b9b;
    box-sizing: border-box;
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input.form-radio ~ .radio-checkmark {
    background-color: white;
  }

  /* When the radio button is checked, add a lendico color background */
  .radio-container input.form-radio:checked ~ .radio-checkmark {
    background-color: white;
    border-color: #696969;
  }

  .radio-checkmark:after {
    top: 4px;
    left: 4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    content: '';
    position: absolute;
    display: none;
  }

  .radio-checkmark--checked::after {
    display: block;
    background: #ff6200;
  }

  /*end custom radio button */
</style>
