<template>
  <IngCard class="loading-wrapper">
    <div class="loading">
      <svg class="circular">
        <circle class="path-bg" />
        <circle class="path" />
      </svg>
    </div>
  </IngCard>
</template>

<script>
  import IngCard from '@/commons/ing/IngCard';
  export default {
    name: 'IngLoading',
    components: {
      IngCard,
    },
  };
</script>

<style scoped lang="scss">
  @use 'sass:math';
  @import '@/styles/ing.orange.juice';
  $width: 32px;
  $border: 3;

  div.ing-card.loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(240, 240, 240, 0.8);
    z-index: 10;
  }

  .loading {
    position: relative;
    width: $width;
    height: $width;

    .circular {
      animation: rotate 2s linear infinite;
      height: inherit;
      position: relative;
      width: inherit;

      .path,
      .path-bg {
        cx: 50%;
        cy: 50%;
        fill: none;
        r: math.div($width - $border, 2);
        stroke-width: $border;
        stroke-linecap: round;
      }
      .path {
        animation: dash 1.5s ease-in-out infinite;
        stroke: $Primary-Orange;
      }

      .path-bg {
        stroke: $Primary-Grey300;
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1%, 300%;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 190%, 300%;
      stroke-dashoffset: -100%;
    }

    100% {
      stroke-dasharray: 190%, 300%;
      stroke-dashoffset: -280%;
    }
  }
</style>
