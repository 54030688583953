export const getURLQueryParameters = (...args) => {
  const urlParams = new URLSearchParams(window.location.search);

  return args.reduce((resultUrlMap, currentKey) => {
    resultUrlMap[currentKey] = urlParams.get(currentKey);
    return resultUrlMap;
  }, {});
};

export const updateURLQueryParameters = (args) => {
  const urlParams = buildURLQueryString(args);
  window.history.replaceState(null, '', `?${urlParams}`);
};

export const redirectWithURLQueryParameters = (baseUrl, args) => {
  const urlParams = buildURLQueryString(args);
  window.open(`${baseUrl}?${urlParams}`, '_self');
};

const buildURLQueryString = (args) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (args) {
    Object.keys(args).forEach((currentKey) => {
      urlParams.set(currentKey, args[currentKey]);
    });
  }

  return urlParams.toString();
};
