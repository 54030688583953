<template>
  <button class="len-button" v-bind="$attrs" v-on="$listeners">
    <slot>Weiter</slot>
  </button>
</template>

<script>
  export default {
    name: 'LenButton',
  };
</script>

<style lang="scss" scoped>
  .len-button {
    background: #29bdba;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all 240ms ease-in-out;

    height: 40px;
    padding: 11px 24px;
    border-radius: 8px;

    text-align: center;
    font-family: inherit;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    -webkit-font-smoothing: antialiased;

    &:hover {
      background: #00667b;
    }

    &:disabled {
      cursor: default;
    }
  }
</style>
