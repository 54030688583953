<template>
  <div class="creditworthiness__options">
    <label class="ing-radio-label"> Ihre Bonität </label>
    <div class="ing-radio-button-wrapper">
      <IngRadioButton
        v-for="option in CREDIT_WORTHINESS_OPTIONS"
        :key="option.value"
        v-model="value"
        :label="option.label"
        :value="option.value"
        name="CreditWorthiness"
        class="ing-radio-button"
        :data-qa-id="option.dataQaId"
      />
    </div>
  </div>
</template>
<script>
  import IngRadioButton from '@/commons/ingOrangeJuice/IngRadioButton';
  import CREDIT_WORTHINESS_OPTIONS from '@/constants/CREDIT_WORTHINESS_OPTIONS';

  export default {
    name: 'CreditWorthinessField',
    components: { IngRadioButton },
    props: {
      selectedValue: {
        type: [String, Number, Boolean],
        default: null,
      },
    },
    computed: {
      value: {
        get() {
          return this.selectedValue;
        },
        set(newValue) {
          this.$emit('change', newValue);
        },
      },
    },
    created() {
      this.CREDIT_WORTHINESS_OPTIONS = CREDIT_WORTHINESS_OPTIONS;
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';

  .ing-radio-label {
    display: block;
    margin-bottom: 13px;
    font-family: inherit;
    color: $Primary-Grey600;
    font-size: px2rem(16px);
    line-height: px2rem(24px);
    font-weight: 400;
  }

  .ing-radio-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: inherit;

    @media screen and (min-width: $oneWeb-s) {
      display: block;
      justify-content: unset;
      align-items: unset;
    }
  }

  .ing-radio-button {
    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
