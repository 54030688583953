<template>
  <button class="ing-button" v-bind="$attrs" v-on="$listeners">
    <slot>Bestätigen</slot>
  </button>
</template>

<script>
  export default {
    name: 'IngButton',
  };
</script>

<style lang="scss" scoped>
  .ing-button {
    background: #ff6200;
    color: white;
    border-radius: 8px;
    min-height: 55px;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;

    &:disabled {
      cursor: default;
    }
  }
</style>
