import Axios from 'axios';
import configs from '@/configs';

class Api {
  constructor() {
    this.axios = Axios.create({
      baseURL: configs.env.VUE_APP_FUNNEL_GATEWAY_API,
    });
  }

  async getOfferDetails(offerId) {
    try {
      const response = await this.axios.get(`/bs/offer/${offerId}`);
      return response.data || {};
    } catch (e) {
      throw new Error(e);
    }
  }

  async getAnnuities(payload) {
    try {
      const response = await this.axios.post('/loan/annuities', payload);
      return response.data || {};
    } catch (e) {
      throw new Error(e);
    }
  }
}

/* istanbul ignore next */
function install(Vue) {
  Vue.mixin({
    beforeCreate() {
      const options = this.$options;

      if (options.api !== undefined) {
        this._api = options.api;
      } else {
        this._api = (this.$parent && this.$parent._api) || this;
      }
    },
  });

  Object.defineProperty(Vue.prototype, '$api', {
    get() {
      return this._api;
    },
  });
}

Api.install = install;

export { install, Api };
