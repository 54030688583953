import range from 'lodash/range';

const isValidValue = (value) => value !== undefined && value !== null;

export const calculateYearsInMonths = (months) => {
  if (!isValidValue(months)) {
    return;
  }
  const oneYearInMonths = 12;
  const maxLengthPlusOneYear = months + 12;
  return range(oneYearInMonths, maxLengthPlusOneYear, oneYearInMonths);
};

export const closestYearInMonths = (months) => {
  if (!isValidValue(months)) {
    return;
  }

  const yearInMonths = 12;

  if (months <= 12) {
    return yearInMonths;
  }
  // 0.01 is used to force Math.round to round down cases like x.5
  const years = Math.round(months / yearInMonths - 0.01);
  return years * yearInMonths;
};
