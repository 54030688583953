<template>
  <div class="ing-container" :class="{ 'ing-container--cms': isCMS }">
    <IngCard class="ing-card" :class="{ 'ing-card--cms': isCMS }">
      <IngLoading v-if="initialLoad" />
      <CalculatorForm
        v-model="loan"
        :annuities="annuities"
        :containerIsCMS="isCMS"
      />
    </IngCard>
    <div class="ing-calculator-footer">
      <IngButton
        v-if="isCMS"
        wtlink="firmenkredit_anfragen_calc.content.link_intern"
        class="button-calculate ing-calculator-footer__button"
        @click="handleClick"
      >
        Firmenkredit anfragen
      </IngButton>
    </div>
  </div>
</template>

<script>
  import PRODUCT_TYPES from '@/constants/PRODUCT_TYPES';
  import DEFAULT_VALUES from '@/constants/LOAN_CALCULATOR';
  import CREDIT_WORTHINESS_OPTIONS from '@/constants/CREDIT_WORTHINESS_OPTIONS';
  import QUERY_PARAMS from '@/constants/QUERY_PARAMS';
  import COMMONS from '@/constants/COMMONS';
  import cookie from '@/commons/cookie';

  import IngButton from '@/commons/ingOrangeJuice/IngButton';
  import IngCard from '@/commons/ingOrangeJuice/IngCard';
  import IngLoading from '@/commons/ingOrangeJuice/IngLoading';
  import CalculatorForm from '@/components/LoanCalculator/CalculatorForm';

  import { closestYearInMonths } from '@/utils/time';
  import { keepInRange, roundToClosestHundred } from '@/utils/currency';
  import {
    getURLQueryParameters,
    redirectWithURLQueryParameters,
    updateURLQueryParameters,
  } from '@/utils/querystring/querystring';

  import configs from '@/configs';

  export default {
    name: 'IngLoanCalculator',
    components: {
      CalculatorForm,
      IngButton,
      IngCard,
      IngLoading,
    },
    props: {
      container: {
        type: String,
        default: 'APP',
        validator: (prop) => Object.values(COMMONS.CONTAINER).includes(prop),
      },
    },
    data() {
      return {
        initialLoad: true,
        loan: {
          amount: +DEFAULT_VALUES.DEFAULT_LOAN_AMOUNT,
          length: +DEFAULT_VALUES.DEFAULT_LOAN_LENGTH,
          creditWorthiness: DEFAULT_VALUES.CREDIT_WORTHINESS,
          minAmount: +configs.env.VUE_APP_REGULAR_MIN_AMOUNT,
          maxAmount: +configs.env.VUE_APP_REGULAR_MAX_AMOUNT,
          maxLength: +configs.env.VUE_APP_REGULAR_MAX_DURATION,
        },
        annuities: {},
        error: {},
        creditWorthinessOptions: CREDIT_WORTHINESS_OPTIONS,
      };
    },
    computed: {
      isCMS() {
        return this.container === COMMONS.CONTAINER.CMS;
      },
    },
    watch: {
      loan: {
        handler() {
          const { amount, length, creditWorthiness } =
            this.getValidatedValues();
          this.loan.amount = amount;
          this.loan.length = length;
          this.loan.creditWorthiness = creditWorthiness;

          this.updateQueryString();
          if (!this.initialLoad) {
            this.getAnnuities();
          }
        },
        immediate: true,
      },
    },
    created() {
      this.toggleHtmlClass('add');
    },
    beforeDestroy() {
      this.toggleHtmlClass('remove');
    },
    async mounted() {
      await this.getAnnuities();
      this.updateQueryString();
      // `ucEvent` triggers twice: `onInitialPageLoad` and `onUpdateServices`
      window.addEventListener('ucEvent', ({ detail }) => {
        this.handleWTCookies(detail.BB_Campaign);
      });
      // ucEvent trigger at `onInitialPageLoad` is not consistent
      // so initiating it on mount based on the `window.dataLayer`
      if (window.dataLayer) {
        this.handleWTCookies(window.dataLayer[0].BB_Campaign);
      }
      this.initialLoad = false;
    },
    methods: {
      handleWTCookies(bbConsent) {
        const wtCodes = ['wt_mc', 'wt_ga', 'wt_kw'];
        if (!bbConsent) {
          this.removeWTCookies(wtCodes);
        } else {
          if (this.isCMS) {
            let urlContainsWtCodes = false;
            wtCodes.forEach((code) => {
              const urlWTCode = getURLQueryParameters(code);
              if (urlWTCode[code]) {
                if (!urlContainsWtCodes) {
                  urlContainsWtCodes = true;
                  this.removeWTCookies(wtCodes);
                }
                cookie.set(`openBusiness_${code}`, urlWTCode[code], 1);
              }
            });
          }
        }
      },
      removeWTCookies(wtCodes) {
        wtCodes.forEach((code) => {
          cookie.remove(`openBusiness_${code}`);
        });
      },
      toggleHtmlClass(method) {
        if (this.isCMS) {
          document
            .getElementsByTagName('html')[0]
            .classList[method]('ing-calculator-cms-container');
        }
      },
      sendClickInfo(wtLink) {
        window?.wt?.sendinfo({
          contentId: window.wt.contentId,
          linkId: wtLink,
        });
      },
      handleClick(event) {
        if (this.isCMS) {
          const antragBaseUrl = configs.env.VUE_APP_BASE_APPLICATION_URL;
          const { amount, length, creditWorthiness } = this.loan;
          this.sendClickInfo(event.target.getAttribute('wtlink'));
          redirectWithURLQueryParameters(antragBaseUrl, {
            amount,
            length,
            creditWorthiness,
          });
        }
      },
      updateQueryString() {
        let { amount, length, creditWorthiness } = this.getValidatedValues();

        updateURLQueryParameters({
          [QUERY_PARAMS.AMOUNT]: amount,
          [QUERY_PARAMS.LENGTH]: length,
          [QUERY_PARAMS.CREDIT_WORTHINESS]: creditWorthiness,
        });
      },
      validateAmount(amount) {
        return keepInRange(
          roundToClosestHundred(amount),
          this.loan.minAmount,
          this.loan.maxAmount
        );
      },
      validateLength(length) {
        return keepInRange(
          closestYearInMonths(length),
          DEFAULT_VALUES.DEFAULT_MINIMUM_DURATION,
          this.loan.maxLength
        );
      },
      validateCreditWorthiness(value) {
        const creditWorthiness = CREDIT_WORTHINESS_OPTIONS.find(
          (item) => item.value === value
        );

        return creditWorthiness
          ? creditWorthiness.value
          : DEFAULT_VALUES.CREDIT_WORTHINESS;
      },
      getValidatedValues() {
        let { amount, length, creditWorthiness } = this.loan;
        const {
          amount: urlAmount,
          length: urlLength,
          creditWorthiness: urlCreditWorthiness,
        } = getURLQueryParameters(
          QUERY_PARAMS.AMOUNT,
          QUERY_PARAMS.LENGTH,
          QUERY_PARAMS.CREDIT_WORTHINESS
        );
        if (this.initialLoad) {
          amount = urlAmount && !isNaN(+urlAmount) ? urlAmount : amount;
          length = urlLength && !isNaN(+urlLength) ? urlLength : length;
          creditWorthiness = urlCreditWorthiness || creditWorthiness;
        }

        return {
          amount: this.validateAmount(amount),
          length: this.validateLength(length),
          creditWorthiness: this.validateCreditWorthiness(creditWorthiness),
        };
      },
      async getAnnuities() {
        try {
          const { amount, length, creditWorthiness } =
            this.getValidatedValues();
          const payload = {
            amount: amount,
            duration: parseInt(length),
            loanType: PRODUCT_TYPES.LENDICO,
            creditWorthiness,
          };
          this.annuities = await this.$api.getAnnuities(payload);
        } catch (error) {
          this.error = error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';
  @import '@/styles/normalize.fonts';

  .ing-container {
    font-family: ING Me, INGMe, Times New Roman, Arial, sans-serif;
    width: 100%;
    max-width: 1022px;
    margin: 0 auto;
    letter-spacing: -0.02em;

    &--cms {
      max-width: 914px;
    }

    * {
      font-family: inherit;
    }

    .ing-card {
      position: relative;
    }
  }

  .ing-calculator-footer {
    display: flex;
    flex-direction: row;
    margin-top: 22px;

    @media screen and (min-width: map-get($breakpoints, medium)) {
      justify-content: flex-end;
    }

    &__button.button-calculate {
      width: 100%;
      @media screen and (min-width: map-get($breakpoints, medium)) {
        width: auto;
      }
    }
  }
</style>
