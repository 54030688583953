<template>
  <form @submit.prevent="handleSubmitForm">
    <LenCard class="len-widget">
      <TextInput
        v-model="loanAmount"
        label="Betrag"
        :caption="amountCaption"
        icon="€"
        :disabled="readOnly"
        class="len-widget__column"
        @blur="handleLoanAmountBlur"
      />

      <LenDropdown
        v-model="loan.length"
        label="Laufzeit"
        :caption="lengthCaption"
        :options="loanLengthOptions"
        :disabled="readOnly"
        class="len-widget__column"
        @input="handleLoanLengthChange"
      />

      <!-- Not Readonly view-->
      <div
        v-if="!readOnly"
        class="len-widget__column len-widget__column--large"
      >
        <div class="len-widget__rate">
          <div class="len-widget__rate__label">Monatliche Rate*</div>
          <div class="len-widget__rate__amount">
            {{ monthlyRate }}
            €
          </div>
        </div>

        <div class="len-widget__button">
          <div class="len-widget__button__label">
            Unverbindlich und kostenlos!
          </div>
          <LenButton> Anfrage starten </LenButton>
        </div>

        <div class="len-widget__tooltip">
          <LenTooltip> Kreditentscheidung in 48 Std. </LenTooltip>
        </div>
      </div>

      <!-- Readonly view-->
      <div v-if="readOnly" class="len-widget__column len-widget__column--large">
        <div class="len-widget__property-wrapper">
          <div class="len-widget__property">
            <span class="len-widget__property__label"> Monatliche Rate* </span>
            <span class="len-widget__property__label">
              Fester Sollzins p.a.*
            </span>
          </div>
          <div class="len-widget__property">
            <span class="len-widget__property__amount">
              {{ monthlyRate }}
              €
            </span>
            <span class="len-widget__property__amount">
              {{ nominalInterestRate }}
              %
            </span>
          </div>
        </div>

        <div class="len-widget__back">
          <a
            role="button"
            class="len-widget__back__button"
            @click.prevent="toggleReadOnly(true)"
          >
            Anfrage ändern
          </a>
        </div>
      </div>
    </LenCard>
    <div class="len-widget__label" :style="`color: ${captionColor}`">
      *Diese unverbindliche Beispielberechnung zeigt die monatliche Rate in der
      besten Risikoklasse.
    </div>
  </form>
</template>

<script>
  import axios from 'axios';
  import numeral from 'numeral';
  import qs from 'query-string';
  import configs from '@/configs';
  import NUMBER_FORMAT from '@/constants/NUMBER_FORMAT';
  import DEFAULT_VALUES from '@/constants/LOAN_CALCULATOR';
  import PRODUCT_TYPES from '@/constants/PRODUCT_TYPES';
  import LenButton from '@/commons/lendico/LenButton';
  import TextInput from '@/commons/lendico/TextInput';
  import LenDropdown from '@/commons/lendico/LenDropdown';
  import LenTooltip from '@/commons/lendico/LenTooltip';
  import LenCard from '@/commons/lendico/LenCard';
  import { calculateYearsInMonths, closestYearInMonths } from '@/utils/time';
  import { keepInRange, roundToClosestHundred } from '@/utils/currency';
  import { gtmTrackingMixin } from '@/mixins/gtmTrackingMixin';

  export default {
    name: 'LoanCalculator',
    components: {
      LenButton,
      TextInput,
      LenDropdown,
      LenTooltip,
      LenCard,
    },
    mixins: [gtmTrackingMixin],
    props: {
      captionColor: {
        type: String,
        default: '#FFFFFF',
      },
    },
    data() {
      return {
        loan: {
          amount: DEFAULT_VALUES.DEFAULT_LOAN_AMOUNT,
          length: DEFAULT_VALUES.DEFAULT_LOAN_LENGTH,
          minAmount: +configs.env.VUE_APP_REGULAR_MIN_AMOUNT,
          maxAmount: +configs.env.VUE_APP_REGULAR_MAX_AMOUNT,
          maxLength: +configs.env.VUE_APP_REGULAR_MAX_DURATION,
        },
        annuities: null,
        readOnly: false,
      };
    },
    computed: {
      loanAmount: {
        get() {
          return numeral(this.loan.amount).format(
            NUMBER_FORMAT.WITHOUT_DECIMAL
          );
        },
        set(value) {
          this.loan.amount = numeral(value).value();
        },
      },
      amountCaption() {
        const min = numeral(this.loan.minAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        const max = numeral(this.loan.maxAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        return `${min} - ${max} €`;
      },
      loanLengthOptions() {
        return calculateYearsInMonths(this.loan.maxLength).map(
          (value, index) => {
            const year = index + 1;
            const labelSuffix = year === 1 ? 'Jahr' : 'Jahre';

            return {
              label: `${year} ${labelSuffix}`,
              value,
            };
          }
        );
      },
      lengthCaption() {
        return `1 - ${this.loanLengthOptions.length} Jahre`;
      },
      monthlyRate() {
        const projectMonthlyRepayment = this.annuities
          ? +this.annuities.projectMonthlyRepayment
          : 0;
        return numeral(projectMonthlyRepayment).format(
          NUMBER_FORMAT.WITH_TWO_DECIMALS
        );
      },
      nominalInterestRate() {
        const nominalInterestRate = this.annuities
          ? +this.annuities.nominalInterestRate
          : 0;
        return numeral(nominalInterestRate).format(
          NUMBER_FORMAT.WITH_TWO_DECIMALS
        );
      },
      isAntrag() {
        return window.location.href.includes(
          configs.env.VUE_APP_OLD_BASE_APPLICATION_URL
        );
      },
    },
    mounted() {
      const { amount, length, minAmount, maxAmount, maxLength } = this.loan;
      const query = qs.parse(window.location.search);

      // currentStep is only for backwards compatibility during new CMS release.
      if (query.readOnly === 'true' || query.currentStep === '2') {
        this.toggleReadOnly();
      }
      this.loan.amount = this.validateAmount(
        query.amount || amount,
        minAmount,
        maxAmount
      );
      this.loan.length = this.validateLength(query.length || length, maxLength);
      this.updateQueryString();
      this.getAnnuities();
    },
    methods: {
      handleLoanAmountBlur() {
        const { amount, minAmount, maxAmount } = this.loan;

        this.loan.amount = this.validateAmount(amount, minAmount, maxAmount);
        this.updateQueryString();
        this.getAnnuities();
      },
      handleLoanLengthChange() {
        this.sendTrackingEvent({
          event: 'GTMevent',
          eventCategory: 'loan-calculator',
          eventAction: 'fields-interaction',
          eventLabel: `length:${this.loan.length}`,
          applicationLocation: 'Lendico',
        });

        this.updateQueryString();
        this.getAnnuities();
      },
      handleSubmitForm() {
        const { amount, length } = this.loan;
        this.sendTrackingEvent({
          event: 'GTMevent',
          eventCategory: 'loan-calculator',
          eventAction: 'submit-clicks',
          eventLabel: `amount:${amount}|length:${length}|rate:${this.monthlyRate}`,
          applicationLocation: 'Lendico',
        });

        if (this.isAntrag) {
          this.toggleReadOnly();
          return;
        }
        this.redirectToAntrag();
      },
      toggleReadOnly(editButtonClicked = false) {
        if (editButtonClicked) {
          const { amount, length } = this.loan;
          this.sendTrackingEvent({
            event: 'GTMevent',
            eventCategory: 'loan-calculator',
            eventAction: 'edit-clicks',
            eventLabel: `amount:${amount}|length:${length}|rate:${this.monthlyRate}`,
            applicationLocation: 'Lendico',
          });
        }
        this.readOnly = !this.readOnly;
      },
      redirectToAntrag() {
        const antragBaseUrl = configs.env.VUE_APP_OLD_BASE_APPLICATION_URL;
        const { amount, length } = this.loan;
        const queryString = qs.stringify({
          amount,
          length,
          readOnly: true,
        });

        window.open(`${antragBaseUrl}?${queryString}`, '_self');
      },
      validateAmount(value, min, max) {
        return keepInRange(roundToClosestHundred(value), min, max);
      },
      validateLength(value, max) {
        return keepInRange(closestYearInMonths(value), 12, max);
      },
      updateQueryString() {
        if (!this.isAntrag) {
          return;
        }
        const { amount, length } = this.loan;
        const queryString = {
          ...qs.parse(window.location.search),
          amount,
          length,
        };

        window.history.replaceState(null, '', `?${qs.stringify(queryString)}`);
      },
      async getAnnuities() {
        const api = configs.env.VUE_APP_FUNNEL_GATEWAY_API;
        const { amount, length } = this.loan;

        const response = await axios.post(`${api}/loan/annuities`, {
          amount,
          duration: length,
          loanType: PRODUCT_TYPES.LENDICO,
        });

        if (response && response.data) {
          this.annuities = response.data;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';

  .len-widget {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1048px;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;

    &__column {
      flex: 0 25%;

      &--large {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 42%;
      }
    }

    &__tooltip {
      position: absolute;
      top: -20px;
      right: -11px;
    }

    &__rate__label,
    &__rate__amount {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
    }

    &__rate__label {
      color: #2e2e2e;
      margin-bottom: 10px;
      text-align: left;
    }

    &__rate__amount {
      color: #29bdba;
      height: 40px;
      line-height: 42px;
      text-align: left;
    }

    &__property-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__property {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;

      &__label {
        color: #2e2e2e;
        width: 160px;
        text-align: left;
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }

      &__amount {
        color: #575757;
        text-align: left;
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      white-space: nowrap;

      &__label {
        margin-bottom: 10px;
        line-height: 14px;
        color: #575757;
        font-size: 14px;
      }
    }

    &__back {
      &__button {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #29bdba;
        text-decoration: underline;
        cursor: pointer;

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .len-widget__label {
    width: 100%;
    padding-top: 10px;

    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
  }

  /*
  * Tablet
  */
  @media screen and (max-width: $desktop-s) {
    .len-widget {
      &__column {
        flex: 0 46%;
        &--large {
          align-items: flex-start;
          margin-top: 20px;
          flex: 0 100%;
        }
      }

      &__button {
        align-items: center;
        position: relative;

        &__label {
          position: absolute;
          line-height: 12px;
          font-size: 12px;
          margin: 0;
          bottom: -17px;
        }
      }

      &__rate__amount {
        line-height: 18px;
        height: auto;
      }

      &__back {
        align-self: flex-end;
      }

      &__property-wrapper {
        width: 46%;
      }

      &__property {
        &__label {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        &__amount {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  /*
  * Mobile
  */
  @media screen and (max-width: $mobile-l) {
    .len-widget {
      &__column {
        margin-top: 10px;
        flex: 0 100%;
        &--large {
          flex: 0 100%;
          flex-direction: column;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      &__tooltip {
        top: -30px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
      }

      &__button {
        width: 100%;

        &__label {
          margin-bottom: 6px;
          position: relative;
          bottom: auto;
        }
      }

      &__rate {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 10px 0 20px 0;

        &__label,
        &__amount {
          font-size: 16px;
          line-height: 16px;
          margin: 0;
          height: auto;
        }

        &__amount {
          min-width: 70px;
        }
      }

      &__property-wrapper {
        margin: 10px 0;
        width: 100%;
      }

      &__property {
        font-size: 16px;
        line-height: 16px;

        &__label {
          &:not(:last-child) {
            margin-bottom: 7px;
          }
        }
        &__amount {
          &:not(:last-child) {
            margin-bottom: 7px;
          }
        }
      }

      &__back {
        margin-top: 20px;
        margin-bottom: 5px;
        align-self: center;
      }
    }

    .len-widget__label {
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;
    }
  }
</style>
