<template>
  <div
    class="ing-native-select"
    :class="{ 'ing-native-select--disabled': disabled }"
  >
    <select
      class="ing-native-select__element"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="listeners"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <div class="ing-native-select__icon">
      <IngTriangleIcon />
    </div>
  </div>
</template>

<script>
  import IngTriangleIcon from '@/assets/IngTriangleIcon';
  import inputMixin from '@/mixins/inputMixin';

  export default {
    name: 'IngNativeSelect',
    components: {
      IngTriangleIcon,
    },
    mixins: [inputMixin],
    inheritAttrs: false,
    props: {
      options: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';

  .ing-native-select {
    font-family: inherit;
    position: relative;
    background: $Primary-white;
    color: $Primary-Grey600;
    box-sizing: border-box;
    height: px2rem(40px);
    width: 100%;
    display: flex;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &__icon,
    &__element {
      font-size: px2rem(16px);
      font-weight: 400;
      cursor: pointer;
      font-family: inherit;
    }

    &__icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 11px 13px;
      z-index: 0;
    }

    &__element {
      color: $Primary-Grey600;
      font-weight: bold;
      text-align: left;
      font-size: px2rem(16px);
      padding: 0 44px 0 16px;
      background: transparent;
      outline: none;
      border-radius: 4px;
      border: 1px solid $Primary-Grey400;
      box-shadow: inset 0 2px 2px $Primary-Grey200;
      box-sizing: border-box;
      width: 100%;
      height: px2rem(40px);
      font-family: inherit;
      position: relative;
      z-index: 1;

      /* for Firefox */
      -moz-appearance: none;
      /* for Chrome */
      -webkit-appearance: none;
      /* For IE10 */
      &::-ms-expand {
        display: none;
      }

      &:focus {
        border: 3px solid $Secondary-Indigo500;
        box-shadow: none;
        padding-right: 42px;
        padding-left: 14px;
      }
    }
  }
</style>
