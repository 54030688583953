<template>
  <div class="ing-calculator">
    <div class="ing-calculator__item1">
      <CurrencyInput
        class="ing-calculator__currency-input"
        label="Kreditrahmen"
        icon="EUR"
        :caption="amountCaption"
        :value="loanAmount"
        data-qa-id="loan-amount"
        @input="handleAmountInput"
        @change="handleAmountChange"
      />
    </div>
    <div class="ing-calculator__item2">
      <CurrencyInput
        class="ing-calculator__currency-input"
        label="Erste Auszahlung"
        icon="EUR"
        :caption="disbursementAmountCaption"
        :value="loanDisbursementAmount"
        data-qa-id="first-payout"
        @input="handleDisbursementAmountInput"
        @change="handleDisbursementAmountChange"
      />
    </div>
    <div class="ing-calculator__item3">
      <CreditWorthinessField
        :selectedValue="loanCreditWorthiness"
        @change="handleCreditWorthinessChange"
      />
    </div>
    <div class="ing-calculator__splitter">
      <IngSplitterArrow class="ing-calculator__splitter__icon" />
    </div>
    <div class="ing-calculator__item4">
      <div class="ing-calculator__output">
        <div class="ing-calculator__output-label">Gebundener Sollzins p.a.</div>
        <div
          class="ing-calculator__output-text-value"
          data-qa-id="percentage-interest"
        >
          {{ nominalInterestRate }}
          <span class="ing-calculator__output-text-value--normal"> % </span>
        </div>
      </div>
      <div class="ing-calculator__output">
        <div class="ing-calculator__output-label">
          <span class="one-line"> Erste </span>
          monatliche Rate*
        </div>
        <div
          class="ing-calculator__output-text-value"
          data-qa-id="monthly-rate"
        >
          {{ monthlyRate }}
          <span class="ing-calculator__output-text-value--normal"> EUR </span>
        </div>
        <div class="ing-calculator__output-help-text">
          Zins und 8 % Rückzahlung
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import numeral from 'numeral';

  import NUMBER_FORMAT from '@/constants/NUMBER_FORMAT';
  import CREDIT_WORTHINESS_OPTIONS from '@/constants/CREDIT_WORTHINESS_OPTIONS';

  import CurrencyInput from '@/commons/ing/CurrencyInput';
  import CreditWorthinessField from '@/components/CreditWorthinessField';
  import IngSplitterArrow from '@/assets/IngSplitterArrow';

  export default {
    name: 'WclLoanCalculator',
    components: {
      CurrencyInput,
      CreditWorthinessField,
      IngSplitterArrow,
    },
    model: {
      prop: 'loan',
      event: 'input',
    },
    props: {
      loan: {
        type: Object,
        required: true,
      },
      annuities: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loanAmount: this.loan.amount,
        loanDisbursementAmount: this.loan.disbursementAmount,
        loanCreditWorthiness: this.loan.creditWorthiness,
        creditWorthinessField: CREDIT_WORTHINESS_OPTIONS,
      };
    },
    computed: {
      amountCaption() {
        const min = numeral(this.loan.minAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        const max = numeral(this.loan.maxAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        return `${min} bis ${max} EUR`;
      },
      disbursementAmountCaption() {
        const max = numeral(this.loanAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        return `0 bis ${max} EUR`;
      },
      monthlyRate() {
        const projectMonthlyRepayment = this.annuities
          ? +this.annuities.projectMonthlyRepayment
          : 0;
        return numeral(projectMonthlyRepayment).format(
          NUMBER_FORMAT.WITH_TWO_DECIMALS
        );
      },
      nominalInterestRate() {
        const parsedValue = parseFloat(this.annuities.nominalInterestRate);
        return numeral(parsedValue).format(NUMBER_FORMAT.WITH_TWO_DECIMALS);
      },
    },
    watch: {
      loan: {
        handler() {
          this.loanAmount = this.loan.amount;
          this.loanDisbursementAmount = this.loan.disbursementAmount;
          this.loanCreditWorthiness = this.loan.creditWorthiness;
        },
      },
    },
    methods: {
      handleAmountInput(amount) {
        this.loanAmount = amount;
      },
      handleAmountChange(amount) {
        if (this.loan.amount !== this.loanAmount) {
          this.$emit('input', { ...this.loan, amount });
        }
      },
      handleDisbursementAmountInput(disbursementAmount) {
        this.loanDisbursementAmount = disbursementAmount;
      },
      handleDisbursementAmountChange(disbursementAmount) {
        if (this.loan.disbursementAmount !== this.loanDisbursementAmount) {
          this.$emit('input', { ...this.loan, disbursementAmount });
        }
      },
      handleCreditWorthinessChange(creditWorthiness) {
        this.$emit('input', { ...this.loan, creditWorthiness });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';
  $lt-mobile-l: $mobile-l + 1;

  .ing-calculator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: ING Me, INGMe, Times New Roman, Arial, sans-serif;
    width: 100%;
    letter-spacing: -0.02em;

    * {
      font-family: inherit;
    }

    @media screen and (min-width: $lt-mobile-l) {
      flex-direction: row;
    }

    &__splitter {
      background: #fff;
      margin: 24px 0 32px 0;
      width: calc(100% + 32px);
      transform: translateX(-16px);
      height: 6px;
      position: relative;

      @media screen and (min-width: $lt-mobile-l) {
        width: calc(100% + 80px);
        margin: 24px -80px 32px 0;
        transform: translateX(-40px);
      }
      @media screen and (min-width: $desktop-s) {
        height: auto;
        transform: translateX(0);
        width: 6px;
        margin: -24px 34px -24px 24px;
      }

      &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-13px, -1px);

        @media screen and (min-width: $desktop-s) {
          transform: translate(-8px, -6px) rotate(-90deg);
        }
      }
    }

    &__item1,
    &__item2 {
      width: 180px;

      @media screen and (min-width: $lt-mobile-l) {
        margin-right: 35px;
      }
    }
    &__item2 {
      margin-top: 24px;

      @media screen and (min-width: $lt-mobile-l) {
        margin-top: 0;
      }
    }
    &__item3 {
      width: 95px;
      padding-top: 24px;

      @media screen and (min-width: $lt-mobile-l) {
        padding-top: 20px;
      }
    }
    &__item4 {
      width: 100%;

      @media screen and (min-width: $lt-mobile-l) {
        width: 375px;
      }

      @media screen and (min-width: $desktop-s) {
        width: 311px;
      }
    }

    &__label {
      display: block;
      margin-bottom: 12px;
      font-family: inherit;
      color: #333;
      font-size: 16px;
      line-height: 24px;
    }

    &__text-value {
      font-size: 16px;
      flex: 1 1 auto;
      margin-top: 6px;
      line-height: 24px;
      vertical-align: middle;
      font-weight: 600;

      &--normal {
        font-weight: normal;
        margin-left: 10px;
      }
    }

    &__output {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      color: #333;

      &:first-child {
        margin-bottom: 12px;

        @media screen and (min-width: $desktop-s) {
          margin-top: 16px;
        }

        .ing-calculator__output-label {
          width: 100px;
          @media screen and (min-width: $lt-mobile-l) {
            width: auto;
          }
        }
        .ing-calculator__output-text-value {
          align-self: flex-end;
        }
      }

      &:last-child {
        .ing-calculator__output-text-value {
          align-self: flex-end;
          @media screen and (min-width: $lt-mobile-l) and (max-width: $tablet) {
            align-self: initial;
          }
        }
        .ing-calculator__output-label {
          .one-line {
            display: block;
            @media screen and (min-width: $lt-mobile-l) and (max-width: $tablet) {
              display: initial;
            }
          }
        }
      }

      &-label,
      &-text-value {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;

        &--normal {
          font-weight: normal;
          display: inline-block;
          width: 28px;
        }

        @media screen and (min-width: $lt-mobile-l) {
          font-size: 19px;
          line-height: 28px;
        }
      }

      &-label {
        font-weight: 600;
      }

      &-help-text {
        font-size: 16px;
        line-height: 24px;
        color: #696969;
        font-weight: normal;
      }
    }
  }
</style>
