<template>
  <IngOmniLoanCalculator :offerAsString="offer" />
</template>

<script>
  import '@/locales/de';
  import IngOmniLoanCalculator from '@/components/Omni/IngOmniLoanCalculator';
  import { Api } from '@/api';
  import Vue from 'vue';

  const api = new Api();

  Vue.use(Api);

  export default {
    components: {
      IngOmniLoanCalculator,
    },
    props: {
      offer: {
        type: String,
        required: true,
        default: null,
      },
    },
    api,
  };
</script>
