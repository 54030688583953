<template>
  <div class="len-card">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'LenCard',
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';

  .len-card {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    box-shadow: 2px 2px 8px rgba(46, 46, 46, 0.1);
    border-radius: 4px;
    padding: 40px 40px 45px 40px;
    width: 100%;
  }

  /*
    * Tablet
    */
  @media screen and (max-width: $desktop-s) {
    .len-card {
      padding: 40px;
    }
  }

  /*
    * Mobile
    */
  @media screen and (max-width: $mobile-l) {
    .len-card {
      padding: 22px 14px 20px 14px;
    }
  }
</style>
