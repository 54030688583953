<template>
  <div class="ing-select">
    <label v-if="label" class="ing-select__label">
      {{ label }}
    </label>
    <div v-if="caption" class="ing-select__caption">
      {{ caption }}
    </div>
    <IngNativeSelect
      class="ing-select__element"
      :options="options"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="$listeners"
    />
  </div>
</template>

<script>
  import IngNativeSelect from '@/commons/ingOrangeJuice/IngDropdown/select/IngNativeSelect';

  export default {
    name: 'IngDropdown',
    components: {
      IngNativeSelect,
    },
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      options: {
        required: true,
        type: Array,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';

  .ing-select {
    font-family: inherit;
    align-items: stretch;

    &__label {
      display: block;
      font-family: inherit;
      color: $Primary-Grey600;
      font-size: px2rem(16px);
      font-weight: 400;
      line-height: px2rem(24px);
      margin-bottom: 4px;
    }

    &__caption {
      font-family: inherit;
      font-size: px2rem(14px);
      font-weight: 400;
      line-height: px2rem(20px);
      margin-bottom: 4px;
      color: $Primary-Grey400;
      text-align: left;
      width: 100%;
    }
  }
</style>
