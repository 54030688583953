<template>
  <div class="ing-container">
    <IngCard class="ing-card">
      <IngLoading v-if="initialLoad" />
      <CalculatorForm v-model="loan" :annuities="annuities" />
    </IngCard>
    <div class="ing-calculator-footer">
      <div class="ing-calculator-footer__text">
        *Bei Auszahlung zum Monatsersten und keinen weiteren Auszahlungen
      </div>
    </div>
  </div>
</template>

<script>
  import PRODUCT_TYPES from '@/constants/PRODUCT_TYPES';
  import DEFAULT_VALUES from '@/constants/ING_LOAN_CALCULATOR';
  import CREDIT_WORTHINESS_OPTIONS from '@/constants/CREDIT_WORTHINESS_OPTIONS';
  import QUERY_PARAMS from '@/constants/QUERY_PARAMS';

  import { closestYearInMonths } from '@/utils/time';
  import { keepInRange, roundToClosestHundred } from '@/utils/currency';
  import {
    getURLQueryParameters,
    updateURLQueryParameters,
  } from '@/utils/querystring/querystring';

  import IngCard from '@/commons/ing/IngCard';
  import IngLoading from '@/commons/ing/IngLoading';
  import CalculatorForm from './CalculatorForm';

  import configs from '@/configs';

  export default {
    name: 'WclLoanCalculator',
    components: {
      CalculatorForm,
      IngCard,
      IngLoading,
    },
    data() {
      return {
        initialLoad: true,
        loan: {
          amount: +DEFAULT_VALUES.DEFAULT_LOAN_AMOUNT,
          length: +DEFAULT_VALUES.DEFAULT_LOAN_LENGTH,
          creditWorthiness: DEFAULT_VALUES.CREDIT_WORTHINESS,
          maxLength: +configs.env.VUE_APP_BLACKSEA_MAX_DURATION,
          monthlyRate: 0,
          disbursementAmount: 0,
          minAmount: 0,
          maxAmount: 0,
        },
        annuities: {
          nominalInterestRate: '',
          projectMonthlyRepayment: '',
        },
        creditWorthinessOptions: CREDIT_WORTHINESS_OPTIONS,
        error: {},
      };
    },
    watch: {
      // watch any changes to the loan amount, credit worthiness and first disbursement,
      // validate and update the query strings
      loan: {
        handler(newLoan) {
          this.loan.amount = this.validateAmount(newLoan.amount);
          this.loan.length = this.validateLength(newLoan.length);
          this.loan.disbursementAmount = this.validateDisbursementAmount(
            newLoan.disbursementAmount
          );
          this.loan.creditWorthiness = this.validateCreditWorthiness(
            newLoan.creditWorthiness
          );

          this.updateQueryString();
          if (this.initialLoad) {
            this.loan.disbursementAmount = this.setInitialDisbursementAmountValue(
              this.loan.amount
            );
          } else {
            this.getAnnuities();
          }
        },
      },
    },
    async mounted() {
      await this.getOfferDetails();
      await this.getAnnuities();
      this.updateQueryString();

      this.initialLoad = false;
    },
    methods: {
      validateAmount(amount) {
        return keepInRange(
          roundToClosestHundred(amount),
          this.loan.minAmount,
          this.loan.maxAmount
        );
      },
      validateDisbursementAmount(disbursementAmount) {
        return keepInRange(
          roundToClosestHundred(disbursementAmount),
          0,
          this.loan.amount
        );
      },
      validateLength(length) {
        return keepInRange(
          closestYearInMonths(length),
          12,
          this.loan.maxLength
        );
      },
      validateCreditWorthiness(value) {
        const creditWorthiness = CREDIT_WORTHINESS_OPTIONS.find(
          (item) => item.value === value
        );

        return creditWorthiness
          ? creditWorthiness.value
          : DEFAULT_VALUES.CREDIT_WORTHINESS;
      },
      setInitialDisbursementAmountValue(loanAmount) {
        // a portion out of total loan amount is set as the default value for the disbursement amount field
        return roundToClosestHundred(
          loanAmount *
            (+DEFAULT_VALUES.WCL_DEFAULT_DISBURSEMENT_AMOUNT_PERCENT / 100)
        );
      },
      async getOfferDetails() {
        try {
          const { offerId, amount } = getURLQueryParameters(
            QUERY_PARAMS.OFFER_ID,
            QUERY_PARAMS.AMOUNT
          );

          const offer = await this.$api.getOfferDetails(offerId);
          const { adjustedMinAmount, adjustedMaxAmount } = offer.details;
          const initialLoanAmount =
            amount && !isNaN(+amount) ? amount : adjustedMaxAmount;

          this.loan = {
            ...this.loan,
            amount: initialLoanAmount,
            minAmount: adjustedMinAmount,
            maxAmount: adjustedMaxAmount,
          };
        } catch (error) {
          this.error = error;
        }
      },
      async getAnnuities() {
        try {
          const {
            amount,
            length,
            disbursementAmount,
            creditWorthiness
          } = this.loan;
          const payload = {
            amount,
            disbursementAmount,
            duration: parseInt(length),
            loanType: PRODUCT_TYPES.WCL,
            creditWorthiness,
          };
          this.annuities = await this.$api.getAnnuities(payload);
        } catch (error) {
          this.error = error;
        }
      },
      updateQueryString() {
        const {
          amount,
          length,
          creditWorthiness,
          disbursementAmount
        } = this.loan;
        updateURLQueryParameters({
          [QUERY_PARAMS.AMOUNT]: amount,
          [QUERY_PARAMS.LENGTH]: length,
          [QUERY_PARAMS.CREDIT_WORTHINESS]: creditWorthiness,
          [QUERY_PARAMS.DISBURSEMENT_AMOUNT]: disbursementAmount,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';
  @import '@/styles/normalize.fonts';

  .ing-container {
    font-family: ING Me, INGMe, Times New Roman, Arial, sans-serif;
    width: 100%;
    max-width: 984px;
    margin: 0 auto;
    letter-spacing: -0.02em;

    * {
      font-family: inherit;
    }

    .ing-card {
      position: relative;
    }
  }

  .ing-calculator-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;

    &__text {
      font-size: 16px;
      line-height: 24px;
      color: #333;
    }

    &__button {
      font-weight: 600;
      font-size: 26px;
      line-height: 24px;
      padding: 0 37.5px;
      height: auto;
    }
  }

  @media screen and (max-width: $tablet - 1) {
    .ing-calculator-footer {
      flex-direction: column;

      &__link {
        margin-top: 8px;
      }
    }
  }
</style>
