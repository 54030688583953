<template>
  <WclLoanCalculator />
</template>

<script>
  import Vue from 'vue';
  import '@/locales/de';
  import WclLoanCalculator from '@/components/WclLoanCalculator/index';
  import { Api } from '@/api';

  const api = new Api();

  Vue.use(Api);

  export default {
    components: {
      WclLoanCalculator,
    },
    api,
  };
</script>
