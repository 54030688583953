<template>
  <div class="ing-card">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'IngCard',
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';

  .ing-card {
    background: #f0f0f0;
    box-sizing: border-box;
    padding: 24px 40px;
    border-radius: 16px;
    width: 100%;
  }

  /*
    * Mobile
    */
  @media screen and (max-width: $mobile-l) {
    .ing-card {
      padding: 24px 16px;
    }
  }
</style>
