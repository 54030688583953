<template>
  <div class="ing-card">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'IngCard',
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';

  .ing-card {
    background: $Primary-Grey100;
    box-sizing: border-box;
    padding: px2rem(16px);
    border-radius: 16px;
    width: 100%;

    @media screen and (min-width: $oneWeb-s) {
      padding: px2rem(24px);
    }

    &--cms {
      padding: px2rem(32px) px2rem(16px) px2rem(24px) px2rem(16px);

      @media screen and (min-width: map-get($breakpoints, medium)) {
        padding: px2rem(36px) px2rem(18px) px2rem(27px) px2rem(18px);
      }
      @media screen and (min-width: map-get($breakpoints, large)) {
        padding: px2rem(44px) px2rem(44px) px2rem(33px) px2rem(44px);
      }
    }
  }
</style>
