const isValidValue = (value) => value !== undefined && value !== null;

export const keepInRange = (value, min, max) => {
  if (!isValidValue(value) || !isValidValue(min) || !isValidValue(max)) {
    return;
  }

  if (value <= min) {
    return min;
  } else if (value >= max) {
    return max;
  }

  return value;
};

export const roundToClosestHundred = (number) => {
  if (!isValidValue(number)) {
    return;
  }
  // If we only did Math.round(number/100)*100 and we have a
  // value like 50.149,8, instead of rounding to 50200, it'll round down to 50100
  // To avoid this, we first round 50.149,8 to the next integer (50150) and then to the next hundred
  const roundedToNextInteger = Math.round(number);
  return Math.round(roundedToNextInteger / 100) * 100;
};
