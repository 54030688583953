<template>
  <button class="ing-button" v-bind="$attrs" v-on="$listeners">
    <slot>Bestätigen</slot>
  </button>
</template>

<script>
  export default {
    name: 'IngButton',
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';

  .ing-button {
    background-color: $Primary-Orange;
    color: $Primary-white;
    border-radius: 8px;
    height: px2rem(44px);
    font-weight: 700;
    font-size: px2rem(19px);
    line-height: px2rem(19px);
    text-align: center;
    outline: none;
    border: 3px solid $Primary-Orange;
    padding: px2rem(8px) px2rem(16px);
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    width: auto;

    &:disabled {
      cursor: default;
    }

    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 3px $Primary-white;
    }

    &.button-secondary {
      background-color: $Secondary-Indigo500;
      border-color: $Secondary-Indigo500;
    }
  }
</style>
