<template>
  <div class="len-text-wrapper">
    <div v-if="showLabelBox" class="len-text-label">
      <label class="len-text-label__text">{{ label }}</label>
      <span class="len-text-label__caption">
        {{ caption }}
      </span>
    </div>
    <div
      class="len-text-input"
      :class="{ 'len-text-input--disabled': disabled }"
    >
      <input
        type="text"
        class="len-text-input__element"
        v-bind="$attrs"
        :disabled="disabled"
        v-on="listeners"
      />
      <div class="len-text-input__icon">
        {{ icon }}
      </div>
    </div>
  </div>
</template>

<script>
  import inputMixin from '@/mixins/inputMixin';

  export default {
    name: 'TextInput',
    mixins: [inputMixin],
    inheritAttrs: false,
    props: {
      icon: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      showLabelBox: function() {
        return !!this.label || !!this.caption;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';

  .len-text-wrapper {
    width: 100%;

    .len-text-label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      -webkit-font-smoothing: antialiased;

      &__text {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        color: #2e2e2e;
      }

      &__caption {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #575757;
      }
    }

    .len-text-input {
      position: relative;
      background: #f5f5f5;
      color: #2e2e2e;
      -webkit-font-smoothing: antialiased;

      height: 40px;
      display: flex;
      border-radius: 4px;

      &__icon,
      &__element {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
      }

      &__icon {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        padding: 11px 15px;
      }

      &__element {
        padding: 11px 39px 11px 15px;
        background: transparent;
        outline: none;
        border: none;
        width: 100%;
        font-family: inherit;
      }

      &--disabled {
        .len-text-input__element,
        .len-text-input__icon {
          color: #9d9d9d;
          cursor: default;
          -webkit-text-fill-color: #9d9d9d;
          opacity: 1;
        }
      }
    }
  }

  /*
    * Mobile
    */
  @media screen and (max-width: $mobile-l) {
    .len-text-wrapper {
      .len-text-label {
        padding-bottom: 8px;
        align-items: center;

        &__text {
          font-size: 16px;
          line-height: 15px;
        }

        &__caption {
          font-size: 12px;
          line-height: 12px;
        }
      }

      .len-text-input {
        &__icon,
        &__element {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
</style>
