<template>
  <div :class="containerIsCMS ? 'ing-calculator--cms' : 'ing-calculator--app'">
    <div class="item1">
      <IngCurrencyInput
        class="currency-input"
        label="Kreditbetrag"
        icon="EUR"
        :caption="amountCaption"
        :value="loanAmount"
        data-qa-id="loan-amount"
        @input="handleAmountInput"
        @change="handleAmountChange"
      />
    </div>
    <div class="item2">
      <IngDropdown
        v-model="loanLength"
        label="Laufzeit"
        :options="loanLengthOptions"
        data-qa-id="loan-duration"
        @input="handleLoanLengthChange"
      />
    </div>
    <div class="item3">
      <CreditWorthinessField
        :selectedValue="loanCreditWorthiness"
        :containerIsCMS="containerIsCMS"
        @change="handleCreditWorthinessChange"
      />
    </div>
    <div class="splitter">
      <IngSplitterArrow class="splitter__icon" />
    </div>
    <div class="item4">
      <div v-if="containerIsCMS" class="summary-title">
        Zu diesen Konditionen:
      </div>
      <div class="output">
        <div class="output-label">Gebundener Sollzins p.a.</div>
        <div class="output-text-value" data-qa-id="percentage-interest">
          {{ nominalInterestRate }}
          <span class="output-text-value--normal"> % </span>
        </div>
      </div>
      <div class="output">
        <div class="output-label">
          Monatliche Rate
          <div v-if="!containerIsCMS" class="output-help-text">
            Zins und Rückzahlung
          </div>
        </div>
        <div class="output-text-value" data-qa-id="monthly-rate">
          {{ monthlyRate }}
          <span class="output-text-value--normal"> EUR </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import numeral from 'numeral';

  import NUMBER_FORMAT from '@/constants/NUMBER_FORMAT';
  import CREDIT_WORTHINESS_OPTIONS from '@/constants/CREDIT_WORTHINESS_OPTIONS';

  import IngCurrencyInput from '@/commons/ingOrangeJuice/IngCurrencyInput';
  import IngDropdown from '@/commons/ingOrangeJuice/IngDropdown';
  import CreditWorthinessField from '@/components/LoanCalculator/CreditWorthinessField';
  import IngSplitterArrow from '@/assets/IngSplitterArrow';

  import { calculateYearsInMonths } from '@/utils/time';

  export default {
    name: 'IngLoanCalculator',
    components: {
      IngCurrencyInput,
      IngDropdown,
      CreditWorthinessField,
      IngSplitterArrow,
    },
    model: {
      prop: 'loan',
      event: 'input',
    },
    props: {
      loan: {
        type: Object,
        required: true,
      },
      annuities: {
        type: Object,
        required: true,
      },
      containerIsCMS: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loanAmount: this.loan.amount,
        loanLength: this.loan.length,
        loanCreditWorthiness: this.loan.creditWorthiness,
        creditWorthinessField: CREDIT_WORTHINESS_OPTIONS,
      };
    },
    computed: {
      amountCaption() {
        const min = numeral(this.loan.minAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        const max = numeral(this.loan.maxAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        return `${min} bis ${max} EUR`;
      },
      lengthCaption() {
        const loanLength = this.loanLengthOptions.length;
        return loanLength <= 1 ? '1 Jahr' : `1-${loanLength} Jahre`;
      },
      monthlyRate() {
        const projectMonthlyRepayment = this.annuities
          ? +this.annuities.projectMonthlyRepayment
          : 0;
        return numeral(projectMonthlyRepayment).format(
          NUMBER_FORMAT.WITH_TWO_DECIMALS
        );
      },
      loanLengthOptions() {
        return calculateYearsInMonths(this.loan.maxLength || 0).map(
          (value, index) => {
            const year = index + 1;
            const labelSuffix = year === 1 ? 'Jahr' : 'Jahre';

            return {
              label: `${year} ${labelSuffix}`,
              value,
            };
          }
        );
      },
      nominalInterestRate() {
        const parsedValue = parseFloat(this.annuities.nominalInterestRate);
        return numeral(parsedValue).format(NUMBER_FORMAT.WITH_TWO_DECIMALS);
      },
    },
    watch: {
      loan: {
        handler() {
          this.loanAmount = this.loan.amount;
          this.loanLength = this.loan.length;
          this.loanCreditWorthiness = this.loan.creditWorthiness;
        },
      },
    },
    methods: {
      handleAmountInput(amount) {
        this.loanAmount = amount;
      },
      handleAmountChange(amount) {
        if (this.loan.amount !== this.loanAmount) {
          this.$emit('input', { ...this.loan, amount });
        }
      },
      handleLoanLengthChange(length) {
        this.$emit('input', { ...this.loan, length: +length });
      },
      handleCreditWorthinessChange(creditWorthiness) {
        this.$emit('input', { ...this.loan, creditWorthiness });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/components/LoanCalculator/CalculatorForm/styles/default.scss';
  @import '@/components/LoanCalculator/CalculatorForm/styles/cms.scss';
</style>
