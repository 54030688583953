<template>
  <div class="omni-calc">
    <IngCard class="omni-calc__container">
      <IngLoading v-if="initialLoad" class="omni-calc__loader" />
      <div class="omni-calc__form">
        <IngCurrencyInput
          :value="loan.amount"
          class="omni-calc__amount"
          :label="loanAmountLabel"
          icon="EUR"
          :caption="amountCaption"
          data-test-id="loanAmount"
          @input="handleAmountInput"
          @change="handleAmountChange"
        />
        <IngCurrencyInput
          v-if="isAmazonFlexkredit"
          :value="loan.disbursementAmount"
          class="omni-calc__disburse"
          label="Erste Auszahlung"
          icon="EUR"
          :caption="disbursementAmountCaption"
          data-test-id="loanFirstDisbursement"
          @input="handleDisbursementAmountInput"
          @change="handleDisbursementAmountChange"
        />
        <div
          v-if="isAmazonFirmenkredit"
          data-test-id="loanDuration"
          class="omni-calc__duration"
        >
          <div class="omni-calc__label">Laufzeit</div>
          <div class="omni-calc__value">1 Jahr</div>
        </div>
        <div class="omni-calc__credit-worth">
          <label class="omni-calc__label omni-calc__label--flex">
            Ihre Bonität
          </label>
          <IngRadioButton
            v-for="option in creditWorthinessOptions"
            :key="option.value"
            v-model="loan.creditWorthiness"
            :label="option.label"
            :value="option.value"
            name="CreditWorthiness"
            class="omni-calc__radio"
            :data-qa-id="option.dataQaId"
          />
        </div>
      </div>
      <div class="omni-calc__splitter">
        <IngSplitterArrow class="omni-calc__splitter-icon" />
      </div>
      <div class="omni-calc__output">
        <div class="omni-calc__output-section">
          <div class="omni-calc__output-label">Gebundener Sollzins p.a.</div>
          <div class="omni-calc__output-value" data-test-id="loanInterestRate">
            {{ nominalInterestRate }}
            <span> % </span>
          </div>
        </div>
        <div class="omni-calc__output-section">
          <div
            v-if="isAmazonFlexkredit"
            data-test-id="flexLoanOutputLabel"
            class="omni-calc__output-label"
          >
            Erste <br />Monatliche Rate*
          </div>
          <div
            v-if="isAmazonFirmenkredit"
            data-test-id="firmenLoanOutputLabel"
            class="omni-calc__output-label"
          >
            Monatliche Rate
            <span class="omni-calc__output-extra-info"
              >Zins und Rückzahlung</span
            >
          </div>
          <div
            class="omni-calc__output-value"
            :class="{
              'omni-calc__output-value-flex-credit': isAmazonFlexkredit,
            }"
            data-test-id="loanMonthlyRate"
          >
            {{ monthlyRate }}
            <span> EUR </span>
          </div>
        </div>
        <div
          v-if="isAmazonFlexkredit"
          class="omni-calc__output-extra-info"
          data-test-id="flexLoanOutputLabelExtraInfo"
          >Zins und 8 % Rückzahlung</div
        >
      </div>
    </IngCard>
    <div
      v-if="isAmazonFlexkredit"
      data-test-id="flexLoanFooter"
      class="omni-calc__footer"
    >
      *Bei Auszahlung zum Monatsersten und keinen weiteren Auszahlungen
    </div>
  </div>
</template>

<script>
  import numeral from 'numeral';

  import NUMBER_FORMAT from '@/constants/NUMBER_FORMAT';
  import PRODUCT_TYPES from '@/constants/PRODUCT_TYPES';
  import DEFAULT_VALUES from '@/constants/ING_LOAN_CALCULATOR';
  import CREDIT_WORTHINESS_OPTIONS from '@/constants/CREDIT_WORTHINESS_OPTIONS';
  import QUERY_PARAMS from '@/constants/QUERY_PARAMS';

  import IngCard from '@/commons/ingOrangeJuice/IngCard';
  import IngLoading from '@/commons/ingOrangeJuice/IngLoading';
  import IngCurrencyInput from '@/commons/ingOrangeJuice/IngCurrencyInput';
  import IngRadioButton from '@/commons/ingOrangeJuice/IngRadioButton';
  import IngSplitterArrow from '@/assets/IngSplitterArrow';

  import { closestYearInMonths } from '@/utils/time';
  import { keepInRange, roundToClosestHundred } from '@/utils/currency';
  import {
    getURLQueryParameters,
    updateURLQueryParameters,
  } from '@/utils/querystring/querystring';

  import configs from '@/configs';

  export default {
    name: 'IngOmniLoanCalculator',
    components: {
      IngCard,
      IngLoading,
      IngCurrencyInput,
      IngRadioButton,
      IngSplitterArrow,
    },
    props: {
      offerAsString: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        initialLoad: true,
        loan: {
          amount: +DEFAULT_VALUES.DEFAULT_LOAN_AMOUNT,
          length: +DEFAULT_VALUES.DEFAULT_LOAN_LENGTH,
          creditWorthiness: DEFAULT_VALUES.CREDIT_WORTHINESS,
          maxLength: +configs.env.VUE_APP_BLACKSEA_MAX_DURATION,
          monthlyRate: 0,
          disbursementAmount: 0,
          minAmount: 0,
          maxAmount: 0,
          productCode: '',
        },
        annuities: {},
        error: {},
        creditWorthinessOptions: CREDIT_WORTHINESS_OPTIONS,
        amountInput: 0,
        disbursementAmountInput: 0,
      };
    },
    computed: {
      loanAmountLabel() {
        return this.isAmazonFlexkredit ? 'Kreditrahmen' : 'Kreditbetrag';
      },
      isAmazonFlexkredit() {
        return this.loan.productCode === PRODUCT_TYPES.LINE_OF_CREDIT_CAPS;
      },
      isAmazonFirmenkredit() {
        return (
          this.loan.productCode ===
          PRODUCT_TYPES.TERM_LOAN_EQUAL_INSTALLMENT_CAPS
        );
      },
      loanType() {
        return PRODUCT_TYPES[this.loan.productCode];
      },
      amountCaption() {
        const min = numeral(this.loan.minAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        const max = numeral(this.loan.maxAmount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        return `${min} bis ${max} EUR`;
      },
      disbursementAmountCaption() {
        const max = numeral(this.loan.amount).format(
          NUMBER_FORMAT.WITHOUT_DECIMAL
        );
        return `0 bis ${max} EUR`;
      },
      monthlyRate() {
        const projectMonthlyRepayment =
          +this.annuities?.projectMonthlyRepayment || 0;
        return numeral(projectMonthlyRepayment).format(
          NUMBER_FORMAT.WITH_TWO_DECIMALS
        );
      },
      nominalInterestRate() {
        const parsedValue = parseFloat(this.annuities.nominalInterestRate);
        return numeral(parsedValue).format(NUMBER_FORMAT.WITH_TWO_DECIMALS);
      },
    },
    watch: {
      loan: {
        handler(newLoan) {
          this.loan.amount = this.validateAmount(newLoan.amount);
          this.loan.length = this.validateLength(newLoan.length);
          this.loan.creditWorthiness = this.validateCreditWorthiness(
            newLoan.creditWorthiness
          );
          this.loan.disbursementAmount = this.validateDisbursementAmount(
            newLoan.disbursementAmount
          );

          this.updateQueryString();
          if (this.initialLoad) {
            this.loan.disbursementAmount =
              this.setInitialDisbursementAmountValue(this.loan.amount);
            this.amountInput = this.loan.amount;
            this.disbursementAmountInput = this.loan.disbursementAmount;
          } else {
            this.getAnnuities();
          }
        },
        deep: true,
      },
    },
    async mounted() {
      await this.loadOfferDetails();
      await this.getAnnuities();
      this.updateQueryString();

      this.initialLoad = false;
    },
    methods: {
      handleAmountInput(amount) {
        this.amountInput = amount;
      },
      handleAmountChange() {
        if (this.loan.amount !== this.amountInput) {
          this.loan.amount = this.amountInput;
        }
      },
      handleDisbursementAmountInput(disbursementAmount) {
        this.disbursementAmountInput = disbursementAmount;
      },
      handleDisbursementAmountChange() {
        if (this.loan.disbursementAmount !== this.disbursementAmountInput) {
          this.loan.disbursementAmount = this.disbursementAmountInput;
        }
      },
      updateQueryString() {
        const { amount, length, creditWorthiness, disbursementAmount } =
          this.loan;
        let parametersList = {
          [QUERY_PARAMS.AMOUNT]: amount,
          [QUERY_PARAMS.LENGTH]: length,
          [QUERY_PARAMS.CREDIT_WORTHINESS]: creditWorthiness,
        };
        if (this.isAmazonFlexkredit) {
          parametersList = {
            ...parametersList,
            [QUERY_PARAMS.DISBURSEMENT_AMOUNT]: disbursementAmount,
          };
        }
        updateURLQueryParameters(parametersList);
      },
      validateAmount(amount) {
        return keepInRange(
          roundToClosestHundred(amount),
          this.loan.minAmount,
          this.loan.maxAmount
        );
      },
      validateDisbursementAmount(disbursementAmount) {
        return keepInRange(
          roundToClosestHundred(disbursementAmount),
          0,
          this.loan.amount
        );
      },
      validateLength(length) {
        return keepInRange(
          closestYearInMonths(length),
          12,
          this.loan.maxLength
        );
      },
      validateCreditWorthiness(value) {
        const creditWorthiness = CREDIT_WORTHINESS_OPTIONS.find(
          (item) => item.value === value
        );

        return creditWorthiness?.value || DEFAULT_VALUES.CREDIT_WORTHINESS;
      },
      setInitialDisbursementAmountValue(loanAmount) {
        // a portion out of total loan amount is set as the default value for the disbursement amount field
        return roundToClosestHundred(
          loanAmount *
            (+DEFAULT_VALUES.WCL_DEFAULT_DISBURSEMENT_AMOUNT_PERCENT / 100)
        );
      },
      loadOfferDetails() {
        try {
          const { amount } = getURLQueryParameters(
            QUERY_PARAMS.OFFER_ID,
            QUERY_PARAMS.AMOUNT
          );

          let offer = JSON.parse(this.offerAsString);
          this.loan = {
            ...this.loan,
            amount: amount ?? offer.details.adjustedMaxAmount,
            minAmount: offer.details.adjustedMinAmount,
            maxAmount: offer.details.adjustedMaxAmount,
            productCode: offer.productCode,
          };
        } catch (error) {
          this.error = error;
        }
      },
      async getAnnuities() {
        try {
          const { amount, length, disbursementAmount, creditWorthiness } =
            this.loan;
          let payload = {
            amount,
            duration: parseInt(length),
            loanType: this.loanType,
            creditWorthiness,
          };
          if (this.isAmazonFlexkredit) {
            payload = { ...payload, disbursementAmount };
          }
          this.annuities = await this.$api.getAnnuities(payload);
        } catch (error) {
          this.error = error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/ing.orange.juice';

  .omni-calc {
    font-family: ING Me, INGMe, Times New Roman, Arial, sans-serif;
    width: 100%;
    max-width: 1022px;
    margin: 0 auto;

    &__container {
      font-family: inherit;
      position: relative;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      gap: 20px;
    }
    &__form {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      gap: px2rem(20px);
      flex-basis: 100%;
      @media screen and (min-width: $oneWeb-s) {
        gap: px2rem(48px);
        flex-flow: row wrap;
      }
      @media screen and (min-width: $oneWeb-l) {
        flex-basis: 55%;
        gap: px2rem(20px);
        justify-content: space-between;
      }
    }
    &__label,
    &__value {
      color: #333;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    &__value {
      font-weight: 700;
    }
    &__label--flex {
      flex-basis: 100%;
      @media screen and (min-width: $oneWeb-s) {
        flex-basis: auto;
      }
    }
    &__splitter {
      height: 6px;
      position: relative;
      background: #fff;
      width: calc(100% + ($emSize * 3));
      margin: 0 px2rem(-24px);

      @media screen and (min-width: $oneWeb-m) {
        width: calc(100% + ($emSize * 7));
        margin: 0 px2rem(-52px);
      }
      @media screen and (min-width: $oneWeb-l) {
        width: 6px;
        height: auto;
        margin: px2rem(-24px) 0;
      }
      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-13px, -1px);

        @media screen and (min-width: $oneWeb-l) {
          transform: translate(-8px, -6px) rotate(-90deg);
        }
      }
    }
    &__credit-worth {
      display: flex;
      flex-flow: column wrap;
      @media screen and (max-width: $oneWeb-s) {
        flex-flow: row wrap;
        column-gap: 25px;
      }
    }
    &__output {
      flex-basis: 100%;
      @media screen and (min-width: $oneWeb-s) {
        flex-basis: 65%;
      }
      @media screen and (min-width: $oneWeb-l) {
        flex-basis: 35%;
      }
      &-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $Primary-Grey600;
        &:first-child {
          margin-bottom: 20px;
        }
      }
      &-label,
      &-value {
        font-size: px2rem(19px);
        line-height: px2rem(28px);
        font-weight: 700;
        span {
          font-weight: normal;
          display: block;
        }
      }
      &-value {
        align-self: flex-start;
      }
      &-value-flex-credit {
        align-self: flex-end;
      }

      &-value span {
        display: inline-block;
        width: px2rem(32px);
      }
      &-extra-info {
        font-size: px2rem(14px);
      }
    }

    &__footer {
      text-align: left;
      color: $Primary-Grey600;
      margin-top: 12px;
    }
  }
</style>
