<template>
  <div class="creditworthiness__options">
    <label class="ing-radio-label"> Ihre Bonität </label>
    <IngRadioButton
      v-for="option in CREDIT_WORTHINESS_OPTIONS"
      :key="option.value"
      v-model="value"
      :label="option.label"
      :value="option.value"
      name="CreditWorthiness"
      class="ing-radio-button"
      :data-qa-id="option.dataQaId"
    />
  </div>
</template>
<script>
  import IngRadioButton from '@/commons/ing/IngRadioButton';
  import CREDIT_WORTHINESS_OPTIONS from '@/constants/CREDIT_WORTHINESS_OPTIONS';

  export default {
    name: 'CreditWorthinessField',
    components: { IngRadioButton },
    props: {
      selectedValue: {
        type: [String, Number, Boolean],
        default: null,
      },
    },
    computed: {
      value: {
        get() {
          return this.selectedValue;
        },
        set(newValue) {
          this.$emit('change', newValue);
        },
      },
    },
    created() {
      this.CREDIT_WORTHINESS_OPTIONS = CREDIT_WORTHINESS_OPTIONS;
    },
  };
</script>

<style lang="scss" scoped>
  .ing-radio-label {
    display: block;
    margin-bottom: 12px;
    font-family: inherit;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .ing-radio-button {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
