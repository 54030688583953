<template>
  <div class="len-select-wrapper">
    <div v-if="showLabelBox" class="len-select-label">
      <label class="len-select-label__text">{{ label }}</label>
      <span class="len-select-label__caption">
        {{ caption }}
      </span>
    </div>
    <Native
      v-if="isNative"
      :options="options"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="$listeners"
    />
    <Custom
      v-if="!isNative"
      :options="options"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="$listeners"
    />
  </div>
</template>

<script>
  import Custom from '@/commons/lendico/LenDropdown/select/Custom';
  import Native from '@/commons/lendico/LenDropdown/select/Native';

  const MOBILE_USER_AGENT_REGEX = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  export default {
    name: 'LenDropdown',
    components: {
      Custom,
      Native,
    },
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      options: {
        required: true,
        type: Array,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      showLabelBox: function() {
        return !!this.label || !!this.caption;
      },
      isNative: function() {
        return MOBILE_USER_AGENT_REGEX.test(navigator.userAgent);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';

  .len-select-wrapper {
    width: 100%;

    .len-select-label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      -webkit-font-smoothing: antialiased;

      &__text {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        color: #2e2e2e;
      }

      &__caption {
        color: #575757;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
      }
    }
  }

  /*
    * Mobile
    */
  @media screen and (max-width: $mobile-l) {
    .len-select-wrapper {
      .len-select-label {
        padding-bottom: 8px;
        align-items: center;

        &__text {
          font-size: 16px;
          line-height: 15px;
        }

        &__caption {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
</style>
