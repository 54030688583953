<template>
  <div>
    <div class="len-tooltip">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LenTooltip',
  };
</script>

<style lang="scss" scoped>
  .len-tooltip {
    position: relative;

    background: #3c31c4;
    border-radius: 4px;
    padding: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;

    &:before {
      content: '';
      width: 0;
      height: 0;
      bottom: -6px;
      position: absolute;

      border-left: 4.33px solid transparent;
      border-right: 4.33px solid transparent;

      border-top: 6px solid #3c31c4;
    }
  }
</style>
