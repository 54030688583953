<template>
  <IngLoanCalculator :container="container" @onConfirm="$emit('onConfirm')" />
</template>

<script>
  import '@/locales/de';
  import IngLoanCalculator from '@/components/IngLoanCalculator';
  import { Api } from '@/api';
  import Vue from 'vue';

  const api = new Api();

  Vue.use(Api);

  export default {
    components: {
      IngLoanCalculator,
    },
    props: {
      container: {
        type: String,
        default: 'APP',
      },
    },
    api,
  };
</script>
