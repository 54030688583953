<template>
  <div class="ing-text-wrapper">
    <label v-if="label" class="ing-text-label">{{ label }}</label>
    <div v-if="caption" class="ing-text-caption">
      {{ caption }}
    </div>
    <div class="ing-text-input">
      <input
        ref="input"
        type="text"
        class="ing-text-input__element"
        :disabled="disabled"
        :value="inputValue"
        v-bind="$attrs"
        @blur="onBlur"
        @input="onInput"
      />
      <div class="ing-text-input__icon">
        {{ icon }}
      </div>
    </div>
  </div>
</template>

<script>
  import numeral from 'numeral';

  export default {
    name: 'CurrencyInput',
    inheritAttrs: false,
    props: {
      icon: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        inputValue: this.formatAmount(this.value),
      };
    },
    watch: {
      value() {
        this.inputValue = this.formatAmount(this.value);
      },
    },
    methods: {
      formatAmount(amount) {
        return new Intl.NumberFormat('de-DE').format(amount);
      },
      numeralValue(amount) {
        const numeralValue = numeral(amount).value();
        return numeralValue !== null ? numeralValue : this.value;
      },
      onInput(e) {
        this.$emit('input', this.numeralValue(e.target.value));
      },
      onBlur() {
        const newValue = this.numeralValue(this.inputValue);
        this.$emit('change', newValue);

        // in the case that the new value hasn't changed but the input value has
        // we need to set the input value element to the correct value as otherwise
        // it will not be detected by vue
        this.$refs.input.value = this.formatAmount(newValue);
      },
    },
  };
</script>

<style lang="scss" scoped>
  /**
   * TODO: this is mostly the same as in TextInput, move/rename to common scss file?
   */

  .ing-text-wrapper {
    font-family: inherit;
    align-items: stretch;

    .ing-text-label {
      display: block;
      font-family: inherit;
      color: #333;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .ing-text-caption {
      font-family: inherit;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      margin-bottom: 4px;
      color: #696969;
      text-align: left;
      width: 100%;
    }

    .ing-text-input {
      display: flex;
      align-items: center;
      width: 100%;
      font-family: inherit;
      flex-direction: row;

      &__element {
        font-family: inherit;
        font-weight: bold;
        text-align: right;
        background: #ffffff;
        border: 1px solid #696969;
        box-shadow: inset 0 2px 2px #d9d9d9;
        border-radius: 4px;
        height: 40px;
        width: 144px;
        padding: 0 16px;
        font-size: 16px;
        outline: none;
        transition: all 0.15s ease-in-out;
        box-sizing: border-box;
        color: #333;

        &:focus {
          border: 1px solid #ff6200;
          box-shadow: none;
        }

        &:disabled {
          border: 1px solid #d9d9d9;
          color: #a8a8a8;
        }
      }

      &__icon {
        margin-left: 8px;
        color: #000;
        font-family: inherit;
        line-height: 40px;
        height: 40px;
      }
    }
  }
</style>
