export default {
  CONTAINER: {
    APP: 'APP',
    CMS: 'CMS',
  },
  THEME: {
    LENDICO: 'LENDICO',
    ING: 'ING',
  },
};
