<template>
  <div
    class="len-native-select"
    :class="{ 'len-native-select--disabled': disabled }"
  >
    <select
      class="len-native-select__element"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="listeners"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <div class="len-native-select__icon">
      <LenArrowIcon />
    </div>
  </div>
</template>

<script>
  import LenArrowIcon from '@/assets/LenArrowIcon';
  import inputMixin from '@/mixins/inputMixin';

  export default {
    name: 'NativeSelect',
    components: {
      LenArrowIcon,
    },
    mixins: [inputMixin],
    inheritAttrs: false,
    props: {
      options: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables';

  .len-native-select {
    background: #f5f5f5;
    color: #2e2e2e;
    -webkit-font-smoothing: antialiased;

    position: relative;
    height: 40px;
    display: flex;
    border-radius: 4px;

    &__icon,
    &__element {
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
    }

    &__icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 11px 15px;
      z-index: 0;
    }

    &__element {
      padding: 11px 44px 11px 15px;
      background: transparent;
      outline: none;
      border: none;
      width: 100%;
      position: relative;
      z-index: 1;
      font-family: inherit;

      /* for Firefox */
      -moz-appearance: none;
      /* for Chrome */
      -webkit-appearance: none;
      /* For IE10 */
      &::-ms-expand {
        display: none;
      }
    }
  }

  /*
    * Mobile
    */
  @media screen and (max-width: $mobile-l) {
    .len-native-select {
      &__icon,
      &__element {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
</style>

<style lang="scss">
  .len-native-select--disabled {
    .len-native-select__element,
    .len-native-select__icon {
      color: #9d9d9d;
      cursor: default;
      opacity: 1;

      svg path {
        stroke: #9d9d9d;
      }
    }
  }
</style>
